import React from 'react';

function Skelton() {
  return (
    <div className="animate-pulse cursor-pointer w-[80vw] md:w-56">
      <div className="mb-8 md:ml-8">
        <div className="h-36 bg-gray-300 lg:h-36 w-[92%] mx-auto rounded-2xl hover:scale-110 "></div>
        <div className="px-4 mt-2">
          <div className=" bg-gray-300 rounded-md h-4 w-full"></div>
          <div className="flex justify-between items-center mt-2">
            <div className="bg-gray-300 h-3 w-4 rounded-md mt-1"></div>
            <div className=" bg-gray-300 h-4 w-52 md:w-24 rounded-md mt-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skelton;
