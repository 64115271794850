/* eslint-disable react/prop-types */
import GeocodeMap from './GeocodeMap';
import EventModalSheet from './ModalSheet';
import NoEvents from './NoEvents';

const Events = ({
  allEventsListData,
  setSelectedEvent,
  selectedEvent,
  setOpenModalSheet,
  isOpenModalSheet,
  isCitiesPage,
}) => {
  if (allEventsListData?.length < 1) return <NoEvents />;

  return (
    <div className='mt-4 md:mt-8'>
      <div className='grid sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-10 gap-2'>
        {allEventsListData &&
          allEventsListData?.results?.map((eventItem, i) => (
            <div
              className={`cursor-pointer ${
                isCitiesPage ? 'col-span-2' : 'col-span-2 md:col-span-3'
              }`}
              key={i}
            >
              <div
                onClick={() => {
                  setOpenModalSheet(true);
                  setSelectedEvent(eventItem);
                }}
                className='flex flex-row sm:flex-col  mb-2 md:mb-8 p-3 sm:p-0 shadow border rounded-2xl sm:shadow-none bg-white '
              >
                <EventCard
                  eventDetail={eventItem}
                  isCitiesPage={isCitiesPage}
                />
              </div>
            </div>
          ))}
      </div>
      <EventModalSheet
        isOpenModalSheet={isOpenModalSheet}
        setOpenModalSheet={setOpenModalSheet}
        selectedEvent={selectedEvent}
      />
    </div>
  );
};

export default Events;

const EventCard = ({ eventDetail, isCitiesPage }) => {
  const tailwindCssClassForComponents = {
    cardContainer:
      'text-left sm:text-center sm:leading-tight m-2 space-y-1 my-2',
    cardImage:
      'w-24 h-24 sm:h-48 lg:h-44 sm:w-11/12 object-contain border border-gray-5 bg-gray-50  sm:mx-auto rounded-xl md:rounded-t-2xl md:scale-110 transition duration-300  hover:opacity-80',
    cardHeading:
      'font-inter text-base font-bold leading-tight sm:truncate first-letter:uppercase md:mt-3 whitespace-pre-line',
    cardParagraph:
      'text-sm text-gray-700 sm:truncate whitespace-pre-line first-letter:uppercase',
  };

  return (
    <>
      <img
        className={tailwindCssClassForComponents.cardImage}
        src={
          eventDetail?.images
            ? eventDetail?.images[0]?.image_file
            : eventDetail?.primary_image
        }
        alt={eventDetail?.name}
      />
      <div className={tailwindCssClassForComponents.cardContainer}>
        <h4 className={tailwindCssClassForComponents.cardHeading}>
          {isCitiesPage
            ? eventDetail.name
            : eventDetail?.event_artist?.length &&
              eventDetail.event_artist.map((artist, index) => {
                return (
                  <span key={index}>
                    {artist.first_name + ' ' + artist.last_name}{' '}
                    {eventDetail?.event_artist?.length - 1 > index && ','}{' '}
                  </span>
                );
              })}
        </h4>
        <p className={tailwindCssClassForComponents.cardParagraph}>
          {isCitiesPage
            ? eventDetail?.event_artist?.length > 0 &&
              eventDetail.event_artist.map((artist, index) => {
                return (
                  <span key={index}>
                    {artist.first_name + ' ' + artist.last_name}{' '}
                    {eventDetail?.event_artist?.length - 1 > index && ','}{' '}
                  </span>
                );
              })
            : eventDetail.name}
        </p>
        {!isCitiesPage && (
          <div className='text-sm md:pb-2 mt-2 md:mt-0'>
            {eventDetail?.city_name && (
              <GeocodeMap
                eventAddress={eventDetail.city_name}
                lat={eventDetail.lat}
                lon={eventDetail.lon}
                locationClickable={false}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};
