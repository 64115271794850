/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { baseURL, apiRoutes } from '../Api/ApiRoutes';
import EventsListSidebar from '../Components/EventsListSidebar';
import { BtnSlider } from '../Components/Carousels';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import pollonlogo1 from '../Assets/Icons/pollonlogo1.svg';
import XREnsemble from '../Assets/Icons/XrEnsemlie1.svg';
import { mobileCheck } from '../Constants/Constant';
import Skelton from '../Components/Skelton';
import MapComponent from '../Components/MapComponent';
// import { Loader } from '../Components/loader';

const apiBaseURL = `${baseURL}${apiRoutes.EVENTS}?${apiRoutes.CITY_ID}=`;

const defaultValue = {
  previous: null,
  next: null,
  results: [],
};

function Cities() {
  const { showBy } = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const cityName = searchParams.get('cityName');
  const cityId = searchParams.get('cityId');
  const page = searchParams.get('page');

  const isCitiesPage = showBy === 'cities';
  const isMobile = mobileCheck();

  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(false);
  const [allEventsListData, setAllEventsListData] = useState(defaultValue);
  const [isOpenModalSheet, setOpenModalSheet] = useState(false);
  const [logos, setLogos] = useState([XREnsemble, pollonlogo1]);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [citiesList, setCitiesList] = useState([]);

  const history = useHistory();
  // This list will be visible in the end

  useEffect(() => {
    if (page) {
      fetchPaginatedData(page, cityId);
    } else {
      fetchPaginatedData('1', cityId);
    }
  }, [page, cityId]);

  const fetchCitiesListData = async () => {
    const res = await fetch(baseURL + apiRoutes.CITY_LIST, {
      method: 'GET',
      headers: {
        Authorization: 'Token 6fd66ae9bdb04f1fc63cdd8bf6836a2cc7906abe',
      },
    });
    const data = await res.json();
    setCitiesList(data);
  };

  useEffect(() => {
    fetchCitiesListData();
  }, []);

  console.log({ citiesList });

  const fetchPaginatedData = async (page, city) => {
    setLoading(true);

    const paginatedUrl = apiBaseURL + city + '&page=' + page;
    // Clicked back button.
    if (paginatedUrl) {
      const res = await fetch(paginatedUrl, {
        method: 'GET',
        headers: {
          Authorization: `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      if (res.status >= 400) {
        setAllEventsListData([]);
        console.error('Not Found', paginatedUrl);
      } else {
        const json = await res.json();
        setAllEventsListData((s) => {
          if (s.next && s.next === json.next) return s;
          else {
            return {
              ...s,
              next: json.next,
              previous: json.previous,
              // eslint-disable-next-line no-unsafe-optional-chaining
              results: [...s?.results, ...json.results],
            };
          }
        });
      }
    }
    setLoading(false);
  };

  const handleSelectedCity = (id, name, logos) => {
    setLoading(true);
    setLogos(logos);
    setAllEventsListData(defaultValue);

    history.push(`/events/cities?cityName=${name}&cityId=${id}&page=1`);
    setLoading(false);
  };

  const eventsToRender = isMobile
    ? allEventsListData
    : {
        ...allEventsListData,
        results: (allEventsListData?.results || []).slice(
          (page - 1) * allEventsListData?.results?.length,
          page * allEventsListData?.results?.length
        ),
      };

  return (
    <>
      <Helmet>
        <title>Explore Cities | ARORA</title>
      </Helmet>
      <section className="bg-gray-200 pt-4 pb-12">
        <div className=" main-container mt-14  pt-12">
          <h1 className="heading">ARORA</h1>
          <p className="text-gray-800 text-center my-5 px-5 sm:px-0">
            {isCitiesPage
              ? 'View events in specific city'
              : 'View artist events as per the specific city'}
          </p>
          <BtnSlider
            cityName={cityName}
            handleSelectedCity={handleSelectedCity}
            loading={false}
          />

          <div className="flex flex-col items-center ">
            <p className="font-inter text-sm md:text-lg pt-8 md:pt-4 mb-5">
              Partners in the city
            </p>
            <div className="flex justify-center flex-wrap gap-2 md:gap-6">
              {logos.map((item, i) => (
                <img
                  className="w-20 h-10 md:w-40 md:h-20 object-contain"
                  src={item}
                  alt="logo-images"
                  key={i}
                />
              ))}
            </div>
          </div>
        </div>
      </section>

      <div className=" flex flex-col md:gap-y-6 gap-y-4 mt-5">
        <MapComponent
          cityName={cityName}
          eventsData={eventsToRender}
          eventDataResults={eventsToRender}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          setOpenModalSheet={setOpenModalSheet}
        />
        {!isMobile && loading ? (
          <div className="main-container">
            <div className="grid sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-10 gap-6 mt-20">
              {Array(10)
                .fill('x')
                .map((x, index) => (
                  <div
                    key={index}
                    className={` ${
                      isCitiesPage ? 'col-span-2' : 'col-span-2 md:col-span-3'
                    }`}
                  >
                    <Skelton key={x + index} />
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <EventsListSidebar
            allEventsListData={eventsToRender}
            eventDataResults={eventsToRender}
            setOpenModalSheet={setOpenModalSheet}
            isOpenModalSheet={isOpenModalSheet}
            setSelectedEvent={setSelectedEvent}
            loading={loading}
            selectedEvent={selectedEvent}
            // error={error}
            isMobile={isMobile}
            isCitiesPage={isCitiesPage}
          />
        )}
      </div>
    </>
  );
}

export default Cities;
