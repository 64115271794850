/* eslint-disable react/prop-types */
// import React from "react";
import Events from './EventsComponent';
import { Loader } from './loader';
import { ChevronLeft, ChevronRight } from './Icons';
import NoEvents from './NoEvents';
import { useHistory } from 'react-router-dom';

function EventsListSidebar({
  allEventsListData,
  setSelectedEvent,
  loading,
  selectedEvent,
  setOpenModalSheet,
  isOpenModalSheet,
  isCitiesPage,
  isMobile,
}) {
  const history = useHistory();

  const tailwindCssClassForComponents = {
    paginationButtonClasses:
      'flex bg-lightGreen text-white py-1 px-3 scaleHoverEffect rounded-xl font-light cursor-pointer disabled:opacity-70 disabled:cursor-not-allowed',
  };

  const handlePageIncrement = () => handlePageUpdate(true);
  const handlePageDecrement = () => handlePageUpdate(false);

  const handlePageUpdate = (moveToNextPage) => {
    const url = new URL(window.location);
    let params = new URLSearchParams(url.search);
    const currentPage = params.get('page');
    const pageToSet = moveToNextPage
      ? Number(currentPage) + 1
      : Number(currentPage) - 1;
    params.set('page', pageToSet);

    history.push('/events/cities?' + params.toString());
  };

  if (allEventsListData.results < 1)
    return <NoEvents isCitiesPage={isCitiesPage} />;

  return (
    <div className='bg-gray-300 sm:bg-white mt-3 py-1 sm:pt-0'>
      <div className='main-container'>
        <div className='lg:min-w-full hidden sm:flex justify-between mt-24 mb-4 '>
          <button
            className={tailwindCssClassForComponents.paginationButtonClasses}
            disabled={allEventsListData.previous ? false : true}
            onClick={handlePageDecrement}
          >
            <ChevronLeft className='w-5 h-5 m-0.5 -ml-1' />
            Previous
          </button>

          <button
            className={tailwindCssClassForComponents.paginationButtonClasses}
            disabled={allEventsListData.next ? false : true}
            onClick={handlePageIncrement}
          >
            Next
            <ChevronRight className='w-6 h-6 -mr-1' />
          </button>
        </div>

        {allEventsListData?.results !== null && (
          <Events
            eventDataResults={allEventsListData.results}
            allEventsListData={allEventsListData}
            loading={loading}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            setOpenModalSheet={setOpenModalSheet}
            isOpenModalSheet={isOpenModalSheet}
            isCitiesPage={isCitiesPage}
          />
        )}

        {isMobile && loading && <Loader />}

        {allEventsListData?.next !== null && (
          <button
            type='button'
            className='md:hidden text-white bg-lightGreen font-Sunflower rounded-xl px-3 py-1 sm:px-5 sm:py-2  my-5
        font-medium flex items-center mx-auto text-lg lg:text-2xl scaleHoverEffect 
        hover:text-lightGreen hover:bg-white'
            disabled={allEventsListData.next ? false : true}
            onClick={handlePageIncrement}
          >
            Load More
          </button>
        )}
      </div>
    </div>
  );
}

export default EventsListSidebar;
