/* eslint-disable react/prop-types */
import Sheet from 'react-modal-sheet';
import GeocodeMap from './GeocodeMap';
import viewAR from '../Images/viewAR.png';
import { ImageCarousel } from './Carousels';
import ImageModal from './ImageModal';
import { useState } from 'react';
import { CutIcon } from './Icons';
import { ArtistCard } from '../util/util';

const EventModalSheet = ({
  isOpenModalSheet,
  setOpenModalSheet,
  selectedEvent,
}) => {
  const [openImageModal, setOpenImageModal] = useState(false);

  const checkMediaFormat = (mediaURL) => {
    return mediaURL.includes('.mp3');
  };

  return (
    <>
      <Sheet isOpen={isOpenModalSheet} onClose={() => setOpenModalSheet(false)}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content className='scrollbar-hide' disableDrag={true}>
            {
              <>
                <section className='mt-5 main-container font-inter relative '>
                  <div
                    onClick={() => setOpenModalSheet(false)}
                    className='-top-10 absolute right-6 cursor-pointer'
                  >
                    <CutIcon className='w-7' />
                  </div>
                  <div className='flex flex-col md:flex-row justify-between mt-12'>
                    <div className='order-last md:order-first mt-4 md:w-3/4 px-5 md:px-0'>
                      <h1 className='text-2xl sm:text-3xl xl:text-5xl font-bold pr-4  md:pl-0 lg:pr-14 first-letter:uppercase'>
                        {selectedEvent?.name}
                      </h1>
                      {selectedEvent &&
                        selectedEvent.event_artist.length > 0 && (
                          <h5 className='mt-3'>Artists:</h5>
                        )}
                      {selectedEvent &&
                        selectedEvent.event_artist.length > 0 &&
                        selectedEvent.event_artist.map((item) => {
                          return (
                            <div className='my-3' key={item.id}>
                              <ArtistCard
                                website={item.website}
                                image={item.profile_pic}
                                location={item.short_address}
                                name={item.first_name + ' ' + item.last_name}
                              />
                            </div>
                          );
                        })}

                      {/* <p className="text-xs lg:text-sm text-stone-700 md:pl-0 ">
                        {selectedEvent?.timings}
                      </p> */}
                      {selectedEvent?.ar_link && (
                        <a
                          href={selectedEvent?.ar_link}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <img
                            className='object-contain w-16 mt-2'
                            src={viewAR}
                            alt={selectedEvent?.ar_link}
                          />
                        </a>
                      )}
                      <div className='flex items-end space-x-3 my-5 '>
                        {selectedEvent?.address && (
                          <GeocodeMap
                            eventAddress={selectedEvent.address}
                            lat={selectedEvent.lat}
                            lon={selectedEvent.lon}
                            locationClickable
                          />
                        )}
                      </div>
                      <div className='my-3'>
                        {selectedEvent?.audiovideo?.length > 0 && (
                          <h2 className='font-Sunflower text-lg text-lightGreen  mt-5 '>
                            Audio File
                          </h2>
                        )}
                        {selectedEvent?.audiovideo?.length > 0 &&
                          selectedEvent.audiovideo.map((media, i) => (
                            <div key={i}>
                              {checkMediaFormat(media.file) ? (
                                <AudioPlay
                                  audioUrl={selectedEvent?.audiovideo[0]?.file}
                                />
                              ) : (
                                <VideoPlay
                                  videoUrl={selectedEvent?.audiovideo[0]?.file}
                                />
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className='px-5 md:px-0'>
                      <ImageCarousel
                        setOpenImageModal={setOpenImageModal}
                        selectedEvent={selectedEvent}
                      />
                    </div>
                  </div>
                  <h2 className='font-Sunflower text-2xl text-lightGreen pb-2 font-bold  md:pl-0 mt-5 md:mt-0 md:px-0 px-5'>
                    Description
                  </h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedEvent?.description
                        ? selectedEvent?.description
                        : 'Artist Description',
                    }}
                    className=' md:pl-0 text-sm md:text-base pb-10 md:pb-10 lg:text-lg font-medium leading-loose event-description md:px-0 px-5'
                  ></div>
                </section>
              </>
            }
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop />
        <ImageModal
          openImageModal={openImageModal}
          setOpenImageModal={setOpenImageModal}
          selectedEvent={selectedEvent}
        />
      </Sheet>
    </>
  );
};

export default EventModalSheet;

const AudioPlay = ({ audioUrl }) => {
  return (
    <audio controls>
      <source src={audioUrl} type='audio/mpeg' />
      Your browser does not support the audio element.
    </audio>
  );
};

const VideoPlay = ({ videoUrl }) => {
  return (
    <video width='400' controls>
      <source src={videoUrl} type='video/mp4' />
      Your browser does not support HTML video.
    </video>
  );
};
