/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ManuelaIllera from '../Images/ManuelaIllera.jpeg';
import { CutIcon } from './Icons';
// eslint-disable-next-line react/prop-types
export default function Modal({ open, setOpen }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-lightGrey bg-opacity-50 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full  justify-center p-4 text-center items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-lightGreen px-5 pb-5 md:px-7 md:pb-7 pt-1 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm '>
                <div>
                  <CutIcon
                    onClick={() => setOpen(false)}
                    className=' w-6 sm:w-8 ml-auto  text-white cursor-pointer'
                  />

                  <h2 className='text-center text-white font-semibold text-lg sm:text-3xl pb-1'>
                    Live September 30th
                  </h2>

                  <img
                    className='w-60 mx-auto sm:w-full object-contain'
                    src={ManuelaIllera}
                    alt='Bacon_hands'
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
