import { useEffect } from 'react';
import supportPageImg from '../Images/supportPageImg.png';
import supportPageImg1 from '../Images/supportPageImg1.png';
import { Helmet } from 'react-helmet';
import PartnersSection from '../Components/PartnersSection';
import OurTeam from '../Components/OurTeam';
import ourStoryBlackLash from '../Images/ourStory-blacklash.jpg';
import grandArmyPlaza from '../Images/grandArmyPlaza.png';
import greetPhoto from '../Images/greetPhoto.jpg';
import washingtonSquare from '../Images/washingtonSquare.jpg';

const OurStory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Our Story | ARORA</title>
      </Helmet>
      <section className="mb-16 lg:mb-32">
        <div className="max-w-screen-2xl mx-auto mt-20">
          <div className="flex justify-between">
            <div>
              <img
                className="w-48 sm:w-64 lg:w-96"
                src={supportPageImg}
                alt="Participate Thread"
              />
            </div>
            <div>
              <h1 className="heading text-center py-6  whitespace-nowrap sm:py-10 lg:py-24">
                Our Story
              </h1>
            </div>
            <div>
              <img
                className="w-48 sm:w-64 lg:w-96 "
                src={supportPageImg1}
                alt="Support Thread"
              />
            </div>
          </div>
          {/* Start Here */}

          <section className="mx-auto flex flex-col gap-4  mt-10 md:mt-5">
            <h5 className="text-center text-gray-700 ">We have the power to</h5>
            <h1 className="sub-heading text-center">ARORA</h1>
            <div className="flex justify-center">
              <iframe
                frameBorder="0"
                allowFullScreen="1"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title="YouTube video player"
                className="h-[350px] w-full sm:mx-3 md:w-9/12 md:h-[400px] lg:h-[580px]  2xl:h-[620px]"
                src="https://www.youtube.com/embed/xN8B-ef-rac"
                tabIndex="0"
              ></iframe>
            </div>
            <h5 className="text-lg font-bold font-Sunflower text-center">
              Artworks Featured
            </h5>
            <p className="text-center max-w-lg italic mx-auto -mt-2">
              Mr. Backlash (Angela Davis) by Erin Ko Zitkála-Šá, the name she
              chose herself by katiamess Cassandra by Eva Davidova & Shirin
              Anlen New York Nails by Sue Roh and Leah Roh AOC Fountain
              (Interdependent Futures Series) by Beatrice Glow Mami Wata by
              Minne Atairu G@r1B@ldA! by Carla Gannis
            </p>
            <div className="main-container px-10 max-w-5xl mx-auto text-center font-light leading-relaxed space-y-4">
              <p>
                In December 2021, Anne Wichmann had a vision to make Gender
                Equity Monuments Visible on the Streets of New York and founded
                ARORA (#MUV) along with #MUV co-founders Clara Francesca, Katie
                Peyton Hofstadter and Nam Le.
              </p>
              <p>
                Wichmann approached her XR Ensemble co-founder Francesca with
                the idea, discussing how artists might use technology to create
                a framework to empower representation on a mass scale. According
                to the mayor’s office, New York strives to be{' '}
                <a
                  href="https://women.nyc/about/"
                  target="_blank"
                  rel="noreferrer"
                  className="underline text-blue-500"
                >
                  “the best place in the world for women to succeed”,
                </a>{' '}
                yet its public art tells a different story. 91% of our monuments
                honor men. To create this new public art campaign, they
                consulted with artist Erin Ko, who recommended two
                collaborators: Writer and artist-curator Katie Peyton Hofstadter
                and Nam Le, founder of Pollinate Art. All were enthusiastic to
                join the conversation, and the early team was formed.
              </p>
              <p>
                The co-founders began the campaign by asking: Where are the
                women? Where are the nonbinary people? Where are monuments
                honoring girls of color, nonmale composers and filmmakers,
                pilots and nail technicians and teachers, who are not men? There
                are more statues of horses than notable women! How can anyone
                expect a more equitable world, when towering above them every
                day — on pedestals, on saddles, on 76-foot columns
                <a
                  href="https://en.wikipedia.org/wiki/Columbus_Monument_(New_York_City)"
                  target="_blank"
                  rel="noreferrer"
                  className="underline text-blue-500"
                >
                  {' '}
                  76-foot columns
                </a>
                — are only white men?
              </p>
            </div>
          </section>
          <section className="main-container mt-14 md:mt-20">
            <img src={ourStoryBlackLash} alt="Arora BlackLash" />
            <div className="main-container mt-10 px-10 max-w-5xl mx-auto text-center font-light leading-relaxed space-y-4">
              <p className="italic">
                AR Statue: Mr. Backlash (Angela Davis) by Erin Ko.This new
                monument depicts Angela Davis and two black panthers, juxtaposed
                to the male lions at the New York Public Library.
              </p>
              <p>
                Wichmann and Francesca reached out to artists, including those
                XRE had presented in the Ars Electronica Festival the previous
                spring, and envisioned the presentation through an interactive
                map and audio tour. Pollinate began creating the map and
                website, while Hofstadter reached out to artists and began
                prototyping a digital plaque structure enhancing the narrative
                around the AR monuments, working with the artists to present the
                stories behind these new monuments. Francesca voiced and
                recorded the digital plaques to create a dynamic audio tour of
                nonmale history, with Wichmann producing the final cut of the
                audio tour. Pollinate’s Jacqueline Kok contributed a wonderful
                additional editorial voice, and helped polish the written
                materials.
              </p>
            </div>
          </section>
          <section className="main-container mt-14 ">
            <img
              src={washingtonSquare}
              alt="Arora Washington Square"
              className="w-1/4 mx-auto"
            />
            <div className="main-container mt-10 px-10 max-w-5xl mx-auto text-center font-light leading-relaxed space-y-4">
              <p className="italic">
                AR Statue: Daughter ICE by Snow Yunxue Fu. This new monument
                depicts an early development of a broader virtual human project
                juxtaposed against Washington Square.
              </p>
              <p>
                In the early months of 2022, artists and organizers engaged in
                an exciting dialogue, in an enormous outpouring of creative
                energy.
              </p>
              <p>
                In March 2022, ARORA celebrated its launch in New York by
                presenting 31 diverse, gender-expansive monuments, created by 37
                artists, and juxtaposed against statues of men across the five
                boroughs of New York City. An interactive map guides viewers to
                31 virtual monuments shown in Augmented Reality, while an audio
                tour tells the stories of each sculpture.
              </p>
              <p>
                The response from artists and audiences was inspiring. Artists
                from other cities began reaching out, wanting to bring ARORA to
                their own public spaces. Programming has expanded to include
                performances, panel discussions, and educational resources.
              </p>
            </div>
          </section>
          <section className="main-container mt-14 ">
            <img
              src={grandArmyPlaza}
              alt="Arora Grand Army Plaza"
              className="w-1/4 mx-auto"
            />
            <div className="main-container mt-10 px-10 max-w-5xl mx-auto text-center font-light leading-relaxed space-y-4">
              <p className="italic">
                AR Statue: Excluded: Black Women in American History by Lauren
                Owen. This new monument spotlights the African American women
                who have contributed to our country and society as a whole
                juxtaposed against Grand Army Plaza.
              </p>
              <p>
                By late February, denkFEmale reached out about bringing the
                campaign to Munich, expanding the network with 30 new monuments.
                In early March, artist collective Media Coeli helped secure a
                grant to bring the campaign to Venice. Audiences began posting
                screenshots and videos to social media, including the hashtag
                ARORA. Today, across national and political boundaries, we
                continue to work towards a common goal: to reimagine our public
                spaces and celebrate gender diversity on a mass scale.
              </p>
              <p>
                We continue to fundraise in New York, seeking sponsorship,
                grants and support to promote the original vision and the
                artists who created it, even as the campaign is invited to other
                cities. Each city has its own model and team, whilst taking
                guidance from the original prototyp
              </p>
              <p>Please reach out if you can help us on this journey!</p>
            </div>
          </section>
          <section className="main-container mt-14">
            <img src={greetPhoto} alt="Arora Team" className="w-1/2 mx-auto" />
            <div className="main-container mt-10 px-10 max-w-5xl mx-auto text-center font-light leading-relaxed space-y-4">
              <p className="italic">
                March 2022 #MUV Meet n Greet photo credit Mary Ann Strandell
              </p>
            </div>
          </section>
        </div>
      </section>

      <PartnersSection heading="Founding Team" />

      <OurTeam />
    </>
  );
};

export default OurStory;
