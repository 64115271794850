/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Geocode from 'react-geocode';
import { MapIcon } from './Icons';

const GeocodeMap = ({ eventAddress, lat, lon, locationClickable }) => {
  const [address, setAddress] = useState('');
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
  Geocode.setLanguage('en');
  Geocode.setRegion('es');
  Geocode.setLocationType('ROOFTOP');
  Geocode.enableDebug();

  Geocode.fromLatLng(lat, lon).then(
    (response) => {
      const adress = response.results[0].formatted_address;
      setAddress(adress);
    },
    (error) => {
      console.error(error);
    }
  );
  return (
    <>
      {locationClickable ? (
        <a
          href={`https://www.google.com/maps?q=${
            eventAddress.includes(',') ? '' : eventAddress
          }${' '}${address}`}
          target='_blank'
          rel='noreferrer'
          className='flex justify-center gap-2'
        >
          <div className='relative bg-black h-1.5 w-1.5 sm:h-2.5 ml-1  sm:w-2.5 mt-2 md:mt-1'>
            <MapIcon className='text-red-600 w-5 h-5 sm:w-8 sm:h-8  -ml-1.5 -mt-1.5 sm:-ml-3 sm:-mt-2' />
          </div>

          <div className='ml-1'>{eventAddress}</div>
        </a>
      ) : (
        <div className='flex md:justify-center '>
          <MapIcon className='text-red-600 w-5 h-5 ' />
          <div className='truncate w-48 md:w-auto '>{eventAddress}</div>
        </div>
      )}
    </>
  );
};

export default GeocodeMap;
