/* eslint-disable no-undef */
export const baseURL = process.env.REACT_APP_API_URL;
export const apiRoutes = {
  CITY_LIST: 'city',
  CITY_ID: 'city_id',
  COLLECTIVE: 'collective/',
  EXHIBITION: 'exhibition/',
  EVENTS: 'events/',
  COLLECTIVE_ID: 'collective_id',
};

export const AppRoutes = {
  HOMEPAGE: '/',
  JOIN_US: '/participate-support',
  OUR_STORY: '/our-story',
  EVENTS: '/events/cities?cityName=NYC&cityId=2&page=1',
  ARTIST: '/artist',
};
