/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Events from './EventsComponent';

export function ArtistsComponent(props) {
  const [isOpenModalSheet, setOpenModalSheet] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  return (
    <>
      {/* Artist Card */}
      <div className='grid grid-cols-12 pb-4 border-b'>
        <div className='flex flex-row  md:flex-col col-span-12 md:col-span-3 items-center mt-8 ml-3 md:ml-0 '>
          <img
            className='w-20 h-20 md:w-28 md:h-28 rounded-full object-cover object-top  border bg-gray-300'
            src={props.image}
            alt='pollinate'
          />
          <div className='ml-3 md:ml-0'>
            <p className='font-bold text-sm md:text-base mt-1 truncate '>
              {props.name}
            </p>
            {props?.website && (
              <a href={props.website} target='_blank' rel='noreferrer'>
                <div className='flex underline text-blue-700'>
                  <p className='mr-1 text-sm md:text-base'>Visit Website</p>
                </div>
              </a>
            )}
          </div>
        </div>
        {/* Event Card */}
        <div className='col-span-12 md:col-span-9'>
          <Events
            allEventsListData={{
              results: props.filteredEvents
                ? props.event?.exhibitions?.map((event) => event)
                : props.event?.exhibitions?.map((event) => event),
            }}
            loading={props.loading}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            setOpenModalSheet={setOpenModalSheet}
            isOpenModalSheet={isOpenModalSheet}
            isCitiesPage={false}
          />
        </div>
      </div>
    </>
  );
}
