import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import globe from '../Images/globe.png';
// import speaker from '../Images/speaker.svg';
// import soundWave from '../Images/soundWave.png';
// import soundWave1 from '../Images/soundWave1.png';
import bigGlobe from '../Images/bigGlobe.png';
import Modal from '../Components/Modal';
import HeroImage from '../Images/heroBackground.jpg';
import { Helmet } from 'react-helmet';
import { mediaLinks } from '../Constants/Constant';
import { AppRoutes } from '../Api/ApiRoutes';
import ARORAGREENLOGO from '../Assets/Icons/ARORAGREENLOGO.svg';
import PBSLOGO from '../Assets/Icons/PBS_logo.png';


function Homepage() {
  const [open, setOpen] = useState(false);
  const soundSectionRef = useRef();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <>
      <Helmet>
        <title>HomePage | ARORA</title>
      </Helmet>
      <Modal open={open} setOpen={setOpen} />

      {/* hero-section */}
      <section className=' mt-14 md:mt-20 main-container '>
        <div className='pt-10 pb-10 text-center'>
          <img
            className='w-52 md:w-72 object-contain mx-auto'
            src={ARORAGREENLOGO}
            alt='ARORAGREENLOGO'
          />
          <p className='italic text-sm pt-4'>
            Formerly the #MakeUsVisible Campaign
          </p>
          <h3 className='text-xl lg:text-2xl my-3 text-gray-600 font-light font-Sunflower max-w-sm mx-auto '>
            A new network of monuments for a more inclusive world
          </h3>

          <Link to={AppRoutes.EVENTS}>
            <button
              type='button'
              className='text-white bg-lightGreen font-Sunflower rounded-2xl px-3 py-1 sm:px-5 sm:py-2 
            font-medium flex items-center mx-auto text-lg lg:text-2xl scaleHoverEffect 
            hover:text-lightGreen hover:bg-white'
            >
              Explore Cities
              <img
                className='object-contain w-8 sm:w-10 ml-3 -mb-1'
                src={globe}
                alt='small-globeImg'
              />
            </button>
          </Link>
        </div>
        <img
          src={HeroImage}
          alt='  AR Scultpure G@r1BAldA! by Carla Gannis'
          className=' w-full object-contain object-center'
        />
        <p className='font-light font-inter pb-8 pt-3'>
          Carla Gannis, G@r1BAldA!, 2022. <br />
          Washington Square Park, NYC
        </p>
      </section>

      {/* voices in public spaces */}
      <section ref={soundSectionRef} className='md:py-12'>
        <div className='main-container '>
          <h3 className=' sub-heading max-w-sm px-5 md:max-w-xl mx-auto text-center'>
            A new network of monuments for a more inclusive world
          </h3>
        </div>
        {/* <div className="flex max-w-[1900px] justify-between mx-auto mt-12">
          <div className="hidden md:block">
            <img
              className='lg:w-[600px] object-contain'
              src={soundWave1}
              alt='soundwave'
            />
          </div>
          <div className='mx-auto'>
            <img
              src={speaker}
              className='object-contain w-48 h-48  lg:w-72  ml-3 lg:h-72'
              alt='soundwave'
            />
          </div>
          <div className='hidden md:block'>
            <img
              className='lg:w-[600px] object-contain'
              src={soundWave}
              alt='soundwave'
            />
          </div>
        </div> */}
      </section>

      {/* Our Core Mission  */}
      <section className='px-5 max-w-5xl mx-auto mt-4 md:mt-0'>
        {/* <h1 className="sub-heading">Our Core Mission </h1> */}
        <p className='text-base lg:text-xl  leading-loose lg:leading-relaxed font-inter pt-2 md:pt-5  '>
          Our core mission is to create a framework to empower representation on
          a mass scale. Each individual monument in the network introduces a
          diverse, gender-expansive figure into the public landscape. By
          empowering artists and audiences around the country, ARORA creates a
          framework for collective action, reimagining our public spaces to
          celebrate gender diversity and equity on a mass scale.
        </p>
        <div className='flex flex-col md:flex-row'>
          <p className='text-base lg:text-xl leading-loose lg:leading-relaxed font-inter pt-5 md:pt-6'>
            In March 2022, we launched our network with a 31 new AR monuments,
            created by artists and collectives in public spaces in New York
            City. Since then, we&apos;ve expanded the ARORA network with
            programs in Munich and Worms, as well as a program centering
            biodiversity in Venice. We are building our 2023-2024 programs now!
            We invite curators and artists around the world to join the ARORA
            network by creating augmented reality monuments for equity and
            justice in the streets we share. Contact us if you’d like a free
            toolkit for your city or town!
          </p>
          <img
            className='w-72 mx-auto object-contain'
            src={bigGlobe}
            alt='big-globe'
          />
        </div>
      </section>

      {/* Text only for mobile view */}
      <section className='block md:hidden'>
        <div className='main-container my-8'>
          <p className='text-[#5D4897] italic font-inter leading-loose sm:px-6 text-center max-w-sm mx-auto'>
            Help spread the word! Choose an artwork to access it in AR, take a
            photo, and share your unique image with our hashtag, ARORA, to be
            viewed by our network around the world.
          </p>
        </div>
      </section>

      <section className='px-5 max-w-5xl mx-auto my-10 md:mt-20'>
        <div className='flex flex-col justify-center items-center gap-4'>
          <h3 className='font-Sunflower  font-medium  text-2xl md:text-4xl'>Featured In</h3>
          <a
            href='https://www.pbs.org/video/augmented-reality-animation-at-queens-world-film-festival-qpj8o8/'
            target='_blank'
            rel='noreferrer'
          >
            <img src={PBSLOGO} alt='PBS' className='w-40 md:w-60' />
          </a>
        </div>
      </section>

      {/* CTA */}
      <section className='bg-footerBackground flex justify-center items-center md:py-16 md:my-10   bg-center bg-contain'>
        <div className='main-container text-center max-w-3xl py-6 md:rounded bg-lightGreen text-white w-full'>
          <h2 className=' font-Sunflower  font-medium  text-2xl md:text-4xl  '>
            Participate & Support
          </h2>
          <Link to='/participate-support'>
            <button
              type='button'
              className='px-10 font-Sunflower py-3 text-lightGreen bg-white rounded mt-4 hover:bg-gray-100  '
            >
              Join Us
            </button>
          </Link>
        </div>
      </section>
      <div className='mt-10 lg:mt-0 mb-3 md:mb-16 text-center main-container'>
        <h2 className=' font-Sunflower  font-medium  text-2xl md:text-4xl  '>
          Media/News
        </h2>
        <div className=' mt-5 grid grid-cols-2 md:grid-cols-6 md:gap-y-4'>
          {mediaLinks.map((item) => (
            <a
              href={item.articleLink}
              target='_blank'
              key={item.name}
              rel='noreferrer'
            >
              <img
                src={item.logo}
                alt={item.name}
                className='w-28 h-20 object-contain mx-auto '
              />
            </a>
          ))}
        </div>
      </div>
    </>
  );
}

export default Homepage;
