import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Homepage from './Pages/Homepage';
import SupportPage from './Pages/SupportPage';
import Cities from './Pages/Cities';
import OurStory from './Pages/OurStory';
import RedirectPage from './Pages/RedirectPage';

import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Failure from './Components/Failure';

import './App.css';
import Artist from './Pages/Artist';

function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route exact path='/'>
          <Homepage />
        </Route>
        <Route exact path='/participate-support'>
          <SupportPage />
        </Route>
        <Route exact path='/our-story'>
          <OurStory />
        </Route>
        <Route exact path='/events/:showBy'>
          <Cities />
        </Route>
        <Route exact path='/artist'>
          <Artist />
        </Route>
        <Route exact path='/:city'>
          <RedirectPage />
        </Route>
        <Route exact path='/*'>
          <Failure />
        </Route>
      </Switch>

      <Footer />
    </Router>
  );
}

export default App;
