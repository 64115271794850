import { useEffect } from 'react';
import supportPageImg from '../Images/supportPageImg.png';
import supportPageImg1 from '../Images/supportPageImg1.png';
import MakeUsVisible from '../Images/MakeUsVisible.jpg';
import Donate from '../Images/Donate.png';
import MeetGreet from '../Images/MeetGreet.JPG';
import { Helmet } from 'react-helmet';
const ParticipateSupportPageData = [
  {
    heading: 'Participate in our Events',
    paragraph:
      'ARORA is a global network of Extended Reality Media Art superimposed against on-site physical structures to rally for a more inclusive world. We welcome you to make a social impact conversation visible that matters to your community. ARORANYC and ARORAMunich 2022 focused on Gender Equity, with ARORAVenice focusing on Lagoon Environmental Health. ',
    buttonText: 'Join Now',
    buttonLink: 'https://www.facebook.com/events/1118010388815352',
    image: MakeUsVisible,
  },
  {
    heading: 'Donate',
    paragraph:
      'ARORA NYC was created by volunteers on a profit-share basis. We strive to pay the amazing artists, curators, producers and technications who made this program possible. A little support goes a long way. ',
    buttonText: 'Support the Cause',
    buttonLink: 'https://ioby.org/project/power-makeusvisible',
    image: Donate,
  },
  {
    heading: 'Get in Touch',
    paragraph:
      'Engage with us in creating a framework to empower representation on mass scale. We\'re here for questions, education, visions and more.',
    buttonText: 'Contact Us',
    buttonLink:
      'https://docs.google.com/forms/d/e/1FAIpQLSdYfrCRNI6-MAG25X0vO7u7yfGEbVzJxUdQqQoC0yOCCEnXwg/viewform',
    image: MeetGreet,
  },
];

const SupportPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Participate & Support | ARORA</title>
      </Helmet>
      <section className="mb-10">
        <div className="max-w-screen-2xl mx-auto mt-20">
          <div className="flex justify-between pt-4 md:pt-0">
            <div>
              <img
                className="md:w-96 object-contain"
                src={supportPageImg}
                alt="Participate Thread"
              />
            </div>
            <div>
              <h1 className="heading py-1 sm:py-10 lg:py-24">
                Participate & Support
              </h1>
            </div>
            <div>
              <img
                className="md:w-96 object-contain"
                src={supportPageImg1}
                alt="Support Thread"
              />
            </div>
          </div>
          {/*  */}
          <div className="main-container mt-8">
            {ParticipateSupportPageData.map((section) => (
              <section
                className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8 border-b pb-10 border-black"
                key={section.heading}
              >
                <div className="mt-10 md:mt-0 lg:mt-20">
                  <h2 className="sub-heading">{section.heading}</h2>
                  <p className=" mt-2 mb-4 md:mt-4 md:mb-8">
                    {section.paragraph}
                  </p>
                  <a
                    href={section.buttonLink}
                    target="_blank"
                    rel="noreferrer"
                    className="mainBtn"
                  >
                    {section.buttonText}
                  </a>
                </div>
                <img src={section.image} alt={section.heading} />
              </section>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default SupportPage;
