import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Loader } from '../Components/loader';

const RedirectPage = () => {
  const history = useHistory();

  const { city } = useParams();

  useEffect(() => {
    const cityName = city.toLowerCase();

    switch (cityName) {
      case 'nyc':
        history.push('/events/cities?cityName=NYC&cityId=2&page=1');
        break;
      case 'munich':
        history.push('/events/cities?cityName=Munich&cityId=4&page=1');
        break;
      case 'munich(english)':
        history.push(
          '/events/cities?cityName=Munich (English)&cityId=5&page=1'
        );
        break;
      case 'venice':
        history.push('/events/cities?cityName=Venice&cityId=3&page=1');
        break;
      default:
        history.push('/events/cities?cityName=NYC&cityId=2&page=1');
    }
  }, [city, history]);

  return (
    <div className='w-screen h-screen flex justify-center items-center'>
      <Loader />
    </div>
  );
};

export default RedirectPage;
