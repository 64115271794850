/* eslint-disable react/prop-types */
import bigGlobe from '../Images/bigGlobe.png';
import { useHistory } from 'react-router-dom';
const NoEvents = ({ isCitiesPage }) => {
  const history = useHistory();
  const redirectFunction = () => {
    history.push({
      pathname: '/events/cities',
      search: '?cityName=NYC&cityId=2',
    });
    window.location.reload();
  };

  return (
    <div className='my-6 md:my-16 main-container text-center'>
      <img className='mx-auto' src={bigGlobe} alt='Arora' />
      <h1 className='text-xl md:text-4xl font-semibold   text-center text-lightGreen'>
        {isCitiesPage ? 'No events in the city' : 'No artist found'}
      </h1>
      <button
        onClick={redirectFunction}
        className='py-2 px-4 md:px-8 text-sm md:text-base border-2 text-lightGreen rounded-xl my-6  border-lightGreen'
      >
        Checkout new events in NYC
      </button>
    </div>
  );
};

export default NoEvents;
