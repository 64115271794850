/* eslint-disable no-undef */
/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  InfoWindowF,
} from '@react-google-maps/api';

import './Map.css';
import {
  LATITUDE,
  LONGITUDE,
  MAP_THEME_STYLES,
  DEFAULT_BACK_IMAGE_URL,
} from '../Constants/Constant';
import MapMarker from '../Assets/map_marker.svg';
import { Loader } from './loader';

const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '20px',
};

function MapComponent(props) {
  const [openInfoWindowMarkerId, setopenInfoWindowMarkerId] = useState('');
  const [myMap, setMyMap] = useState('');
  const [mainCenter, setMainCenter] = useState({
    lat: Number(LATITUDE),
    lng: Number(LONGITUDE),
  });
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
  });

  const handleSelectedMarker = (marker_id) => {
    setopenInfoWindowMarkerId(marker_id);
    props.setSelectedEvent('');
  };

  const handleSelectedCard = (item) => {
    props.setOpenModalSheet(true);
    props.setSelectedEvent(item);
  };

  const onLoad = useCallback((map) => setMyMap(map), []);

  useEffect(() => {
    if (myMap && props.eventsData?.results?.length > 1) {
      const bounds = new window.google.maps.LatLngBounds();
      props.eventsData?.results?.map((item) =>
        bounds.extend({
          lat: Number(item.lat),
          lng: Number(item.lon),
        })
      );
      myMap.fitBounds(bounds);
    }
  }, [myMap, props.eventsData.results]);

  useEffect(() => {
    props.selectedEvent && setopenInfoWindowMarkerId('');
  }, [props.selectedEvent]);

  useEffect(() => {
    props.eventsData?.results?.length === 1 &&
      setMainCenter({
        lat: Number(props.eventsData?.results[0]?.lat),
        lng: Number(props.eventsData?.results[0]?.lon),
      });
  }, [props.eventsData.results]);

  return isLoaded ? (
    <>
      <div className=''>
        <div className=' h-[300px] sm:[320px] md:h-[400px] mx-auto max-w-7xl px-2 lg:px-8 '>
          <GoogleMap
            // ref={ref}
            mapContainerStyle={containerStyle}
            center={mainCenter}
            zoom={props.eventsData?.results?.length === 1 ? 18 : 0}
            onLoad={onLoad}
            options={{
              styles: MAP_THEME_STYLES,
              fullscreenControl: false,
              zoomControl: false,
              autoPan: true,
              panControl: true,
              streetViewControl: false,
              keyboardShortcuts: false,
              gestureHandling: 'greedy',
              mapTypeControlOptions: { mapTypeIds: [] },
            }}
          >
            {false ? (
              <>
                {props.eventDataResults?.length > 0 &&
                  props.eventDataResults?.map((item, index) => {
                    return (
                      <MarkerF
                        icon={MapMarker}
                        key={index}
                        position={{
                          lat: Number(item.lat),
                          lng: Number(item.lon),
                        }}
                        onClick={() => handleSelectedMarker(item.id)}
                      >
                        {(openInfoWindowMarkerId === item.id ||
                          props.selectedEvent?.id === item.id) && (
                          <InfoWindowF
                            disableAutoPan={true}
                            onCloseClick={() => setopenInfoWindowMarkerId('')}
                          >
                            <div
                              style={{ maxWidth: '280px' }}
                              onClick={() => handleSelectedCard(item)}
                              className='flex  cursor-pointer'
                            >
                              <img
                                className='h-20 w-20 max-h-16 max-w-16 min-w-[50px]  min-h-[50px] object-cover'
                                src={
                                  item?.images?.length > 0
                                    ? item.images[0].image_file
                                    : DEFAULT_BACK_IMAGE_URL
                                }
                                style={{ borderRadius: '8px' }}
                                alt='info window'
                              />
                              <div className='flex flex-col ml-1.5 justify-center space-y-0.5'>
                                <p className='font-semibold text-xs '>
                                  {item.name}
                                </p>
                                <p className='text-gray-700'>{item.timings}</p>
                              </div>
                            </div>
                          </InfoWindowF>
                        )}
                      </MarkerF>
                    );
                  })}
              </>
            ) : (
              <>
                {props.eventsData.results?.length > 0 &&
                  props.eventsData.results?.map((item, index) => {
                    return (
                      <MarkerF
                        icon={MapMarker}
                        key={index}
                        position={{
                          lat: Number(item.lat),
                          lng: Number(item.lon),
                        }}
                        onClick={() => handleSelectedMarker(item.id)}
                      >
                        {(openInfoWindowMarkerId === item.id ||
                          props.selectedEvent?.id === item.id) && (
                          <InfoWindowF
                            disableAutoPan={true}
                            onCloseClick={() => setopenInfoWindowMarkerId('')}
                          >
                            <div
                              style={{ maxWidth: '280px' }}
                              onClick={() => handleSelectedCard(item)}
                              className='flex  cursor-pointer'
                            >
                              <img
                                className='h-20 w-20 max-h-16 max-w-16 min-w-[50px]  min-h-[50px] object-cover'
                                src={
                                  item?.images?.length > 0
                                    ? item.images[0].image_file
                                    : DEFAULT_BACK_IMAGE_URL
                                }
                                style={{ borderRadius: '8px' }}
                                alt='info window'
                              />
                              <div className='flex flex-col ml-1.5 justify-center space-y-0.5'>
                                <p className='font-semibold text-xs'>
                                  {item.name}
                                </p>
                                <p className='text-gray-700'>{item.timings}</p>
                              </div>
                            </div>
                          </InfoWindowF>
                        )}
                      </MarkerF>
                    );
                  })}
              </>
            )}
          </GoogleMap>
        </div>
      </div>
    </>
  ) : (
    <Loader />
  );
}

export default MapComponent;
