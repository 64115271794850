/* eslint-disable react/prop-types */
/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CutIcon } from './Icons';
import { ModalImageCarousel } from './Carousels';
export default function ImageModal({
  openImageModal,
  setOpenImageModal,
  selectedEvent,
}) {
  return (
    <Transition.Root show={openImageModal} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-[99999999]'
        onClose={setOpenImageModal}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-70 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10  overflow-scroll'>
          <div className='min-h-full flex  justify-center p-4 text-center items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden h-full rounded-lg bg-gray-100 transition-all sm:my-8 w-[85vw] sm:w-9/12 sm:max-w-5xl mx-auto pb-5 px-6 md:pb-6  md:pt-0.5  md:px-6'>
                <CutIcon
                  onClick={() => setOpenImageModal(false)}
                  className='w-6 h-6 sm:w-8  sm:h-8 ml-auto mb-1 mt-4  text-lightGreen cursor-pointer'
                />
                <ModalImageCarousel
                  setOpenImageModal={setOpenImageModal}
                  selectedEvent={selectedEvent}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
// p-4 sm:p-8 pt-1 sm:pt-1
