/* eslint-disable no-undef */
import { useCallback, useEffect, useState } from 'react';

function useGetFetch(url) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchGet = useCallback(async () => {
    if (!url.includes('null') || url.length > 0 || url !== undefined) {
      try {
        setLoading(true);
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Token ${process.env.REACT_APP_API_TOKEN}`,
          },
        });
        if (res.status >= 400) {
          setData([]);
          console.error('Not Found', url);
        } else {
          const json = await res.json();
          setData(json);
        }
        setLoading(false);
      } catch (err) {
        console.error('Failed to fetch data from endpoint with error', err);
      } finally {
        setLoading(false);
      }
    }
  }, [url]);

  useEffect(() => {
    fetchGet();
  }, [url, fetchGet]);
  return [data, loading];
}
export { useGetFetch };
