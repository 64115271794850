/* eslint-disable no-useless-escape */
import ClaraFrancesca from '../Images/ClaraFrancesca.jpg';
import KatiePeytonHofstadter from '../Images/KatiePeytonHofstadter.jpg';
import AnneWichmann from '../Images/AnneWichmann.jpg';
import director from '../Images/director.jpg';

import XREnsemble from '../Assets/Icons/XrEnsemlie1.svg';
import ClaraFrancescaText from '../Assets/Icons/ClaraFrancescaText.svg';
import KatiePeytonHofstadterText from '../Assets/Icons/KatiePeytonHofstadterText.svg';
import AnneWichmannText from '../Assets/Icons/AnneWichmannText.svg';
import Pollinate from '../Assets/Icons/pollinate.svg';

import ElPais from '../Images/ElPais.png';
import ZEITjUNG from '../Images/ZEITjUNG.png';
import BayerischerRundfunk from '../Images/BayerischerRundfunk.png';
import HausderKunst from '../Images/HausderKunst.png';
import IlGlobo from '../Images/IlGlobo.png';
import LMUMunchen from '../Images/LMUMunchen.png';
import NewYorkUniversity from '../Images/NewYorkUniversity.png';
import XRHubBavaria from '../Images/XRHubBavaria.png';
import PublicArtMunchen from '../Images/PublicArtMunchen.png';
import Suddeutsche from '../Images/Suddeutsche.png';

import BBkLogo from '../Images/BBK_Verbindungslinien_Logo_SW.svg'
import ScavengarLogo from '../Images/Scavengar Logo.png'


export const mediaLinks = [
  {
    logo: ElPais,
    name: 'El País',
    articleLink: `https://elpais.com/ideas/2022-05-20/como-reflejar-una-realidad-mas-justa-solo-con-tu-movil.html?au toplay=1 
    `,
  },
  {
    logo: ZEITjUNG,
    name: 'ZEITjUNG',
    articleLink: 'https://www.zeitjung.de/makeusvisible-kunst-geschlechter-gerechtigkeit-ausstellung-skulpturen/',
  },
  {
    logo: IlGlobo,
    name: 'Il Globo',
    articleLink: 'https://ilglobo.com/news/realta-aumentate-in-giro-per-il-mondo-per-rendere-gli-oppressi-finalmente-visibili-73041/ ',
  },

  {
    logo: NewYorkUniversity,
    name: 'New York University',
    articleLink: 'https://twitter.com/nyuniversity/status/1503736963354005506',
  },
  {
    logo: PublicArtMunchen,
    name: 'Public Art München',
    articleLink: 'https://www.publicartmuenchen.de/veranstaltungen/makeusvisible-x-denkfemale/',
  },
  {
    logo: XRHubBavaria,
    name: 'XR Hub Bavaria',
    articleLink: 'https://xrhub-bavaria.de/makeusvisible-x-denkfemale/',
  },
  {
    logo: HausderKunst,
    name: 'Haus der Kunst',
    articleLink: 'https://www.hausderkunst.de/veranstaltungen/begleitprogramm-zu-past-statements---present-futures-forum',
  },
  {
    logo: Suddeutsche,
    name: 'Süddeutsche Zeitung ',
    articleLink: 'https://www.sueddeutsche.de/muenchen/muenchen-denkmaeler-frauen-digital-denkfemale-interview-1.5313421',
  },
  {
    logo: LMUMunchen,
    name: 'LMU München',
    articleLink: 'https://www.lmu.de/de/newsroom/newsuebersicht/news/fluechtige-erinnerungen.html',
  },
  {
    logo: BayerischerRundfunk,
    name: 'Bayerischer Rundfunk “Capriccio”',
    articleLink: 'https://www.br.de/mediathek/video/capriccio-kulturmagazin-22092022-die-wiesn-ektase-ist-zurueck-raubgraeber-in-bayern-marilyn-monroe-av:62f386aa769a270009fa92be',
  },
  {
    logo: BBkLogo,
    name: 'BBK_Verbindungslinien_Logo_SW',
  }, {
    logo: ScavengarLogo,
    name: 'Scavengar Logo',
  }
];

export const LATITUDE = '40.7492094 ';
export const LONGITUDE = '-73.9815265';
export const DEFAULT_BACK_IMAGE_URL =
  'https://images.unsplash.com/photo-1514483127413-f72f273478c3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80';
export const MAP_THEME_STYLES = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#212121',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#212121',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#181818',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1b1b1b',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#2c2c2c',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#8a8a8a',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#373737',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#3c3c3c',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry',
    stylers: [
      {
        color: '#4e4e4e',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#3d3d3d',
      },
    ],
  },
];

// mobile check

export const mobileCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const partnersLogo = [
  {
    image: XREnsemble,
    name: 'XREnsemble',
  },
  {
    image: AnneWichmannText,
    name: 'AnneWichmann',
  },
  {
    image: KatiePeytonHofstadterText,
    name: 'KatiePeytonHofstadter',
  },
  {
    image: ClaraFrancescaText,
    name: 'ClaraFrancesca',
  },
  {
    image: Pollinate,
    name: 'pollinate',
  },
];

export const makeUsVisibleTeamData = [
  {
    image: AnneWichmann,
    name: 'Anne Wichmann',
    bio: 'Concept & Curator & Organizer & Audio-tour',
    instagramLink: 'https://www.instagram.com/shes_excited/',
  },

  {
    image: KatiePeytonHofstadter,
    name: 'Katie Peyton Hofstadter',
    bio: 'Curator & Organizer',
    instagramLink: 'https://www.instagram.com/katie_peyton_/',
  },
  {
    image: ClaraFrancesca,
    name: 'Clara Francesca',
    bio: 'Curator & Organizer & Audio-tour ',
    instagramLink: 'https://www.instagram.com/clar_esca/',
  },
  {
    image: director,
    name: 'Jacqueline Kok',
    bio: 'Creative Director @ Pollinate Art',
    instagramLink: 'https://www.instagram.com/kok.jacqueline.c/',
  },
];
