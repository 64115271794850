import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import bigGlobe from '../Images/bigGlobe.png';

const Failure = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="my-28 main-container text-center">
        <img className="mx-auto" src={bigGlobe} alt="globe" />

        <h1 className="text-xl md:text-4xl font-semibold   text-center text-lightGreen">
          This page is not exist.
        </h1>
        <Link to="/">
          <button className="py-2 px-4 md:px-8 text-sm md:text-base border-2 text-lightGreen rounded-xl my-6  border-lightGreen">
            Homepage
          </button>
        </Link>
      </div>
    </>
  );
};

export default Failure;
