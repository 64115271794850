import React from 'react';
import { makeUsVisibleTeamData } from '../Constants/Constant';
import { InstagramIcon } from './Icons';
function OurTeam() {
  return (
    <section className="md:bg-footerBackground  bg-no-repeat md:py-8 lg:py-36  bg-center bg-cover mb-10">
      {/* <h2 className="sub-heading text-center pt-10 md:pt-0 md:pb-12">
        Our Team
      </h2> */}
      <div className="main-container">
        <div className="sm:w-10/12 mx-auto">
          <div className="grid sm:grid-cols-4 lg:grid-cols-8 gap-8 md:gap-6 mt-8 md:mt-0">
            {makeUsVisibleTeamData.map(
              ({ name, image, bio, instagramLink }) => (
                <div className="col-span-2 mx-auto" key={name}>
                  <img
                    src={image}
                    alt={name}
                    className="w-full min-w-[95vw]  sm:min-w-full object-contain"
                  />
                  <h2 className="font-bold mt-1 text-lg md:w-3/4">{name}</h2>
                  <p className=" text-gray-700 text-base md:w-3/4 ">{bio}</p>
                  <a
                    href={instagramLink}
                    target="_blank"
                    rel="noreferrer"
                    className="bg-gray-100 hover:bg-white hover:border-gray-100 hover:border w-10 h-10 rounded-full flex justify-center items-center mt-2"
                  >
                    <InstagramIcon />
                  </a>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurTeam;
