/* eslint-disable react/prop-types */
import React from 'react';
import { partnersLogo } from '../Constants/Constant';
const PartnersSection = ({ heading }) => {
  return (
    <section className='text-center  max-w-4xl mx-auto '>
      <h2 className='sub-heading '>{heading ? heading : 'Media/News'}</h2>
      <div className='grid grid-cols-6 sm:grid-cols-10'>
        {partnersLogo.map((partner, id) => (
          <div className='col-span-2 flex items-center' key={id}>
            <img
              src={partner.image}
              key={id}
              alt={partner.name}
              className='mx-auto '
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default PartnersSection;
