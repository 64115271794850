import { useState } from 'react';
// import { Link } from "react-router-dom";
import { CutIcon, HamburgerMenu } from './Icons';
import { NavLink } from 'react-router-dom';
import { AppRoutes } from '../Api/ApiRoutes';
import ARORALOGO from '../Assets/Icons/ARORALOGO.svg';

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);

  return (
    <>
      <nav className="bg-darkGreen fixed z-10 top-0 w-full">
        <div className="md:main-container justify-center mx-auto  md:items-center md:flex">
          <div>
            <div className="flex items-center justify-between py-3 md:py-5 md:block">
              <NavLink to={AppRoutes.HOMEPAGE}>
                <div className="text-4xl font-bold text-white ml-2 md:hidden">
                  <img
                    className="w-28 object-contain"
                    src={ARORALOGO}
                    alt="ARORALOGO"
                  />
                </div>
              </NavLink>

              <div className="md:hidden ">
                <button
                  className="p-2  text-white rounded-md outline-none"
                  onClick={() => setNavbar(!navbar)}
                >
                  {navbar ? (
                    <CutIcon className="w-8 h-8 text-pink-600" />
                  ) : (
                    <HamburgerMenu />
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="md:py-5">
            <div className={` md:block   ${navbar ? 'block' : 'hidden'}`}>
              <ul className="items-center text-white bg-darkGrey md:bg-darkGreen text-center  justify-center md:flex md:space-x-16 lg:space-x-24 md:space-y-0">
                <li
                  onClick={() => setNavbar(false)}
                  className="md:border-0 py-4 md:py-0  border-t-2 border-gray-400"
                >
                  <NavLink
                    exact
                    activeClassName=" md:bg-white md:text-darkGreen  md:font-bold md:rounded-lg"
                    to={AppRoutes.HOMEPAGE}
                    className="font-JosefinSans py-1.5 px-6"
                  >
                    Home
                  </NavLink>
                </li>

                <li
                  onClick={() => setNavbar(false)}
                  className="md:border-0 py-4 md:py-0  border-t-2 border-lightGreen "
                >
                  <NavLink
                    activeClassName=" md:bg-white md:text-darkGreen  md:font-bold md:rounded-lg"
                    to={AppRoutes.OUR_STORY}
                    className="font-JosefinSans py-1.5 px-6"
                  >
                    Our Story
                  </NavLink>
                </li>
                <li
                  onClick={() => setNavbar(false)}
                  className="md:border-0 py-4 md:py-0  border-t-2 border-lightGreen "
                >
                  <NavLink
                    activeClassName="md:bg-white md:text-darkGreen  md:rounded-lg md:font-bold"
                    to={AppRoutes.ARTIST}
                    className="font-JosefinSans py-1.5 px-6"
                  >
                    Artists
                  </NavLink>
                </li>

                <li
                  onClick={() => setNavbar(false)}
                  className="md:border-0 py-4 md:py-0  border-t-2 border-lightGreen"
                >
                  <NavLink
                    activeClassName="md:bg-white md:text-darkGreen  md:rounded-lg md:font-bold"
                    to={AppRoutes.EVENTS}
                    className="font-JosefinSans py-1.5 px-6"
                  >
                    Cities
                  </NavLink>
                </li>

                <li
                  onClick={() => setNavbar(false)}
                  className="md:border-0 py-4 md:py-0  border-t-2 border-lightGreen"
                >
                  <NavLink
                    activeClassName="md:bg-white md:text-darkGreen  md:rounded-lg md:font-bold"
                    className="font-JosefinSans py-1.5 px-6"
                    to={AppRoutes.JOIN_US}
                  >
                    Join Us
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
