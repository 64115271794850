import React, { useState, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import { baseURL } from '../Api/ApiRoutes';
import { SearchIcon } from '../Components/Icons';
import { Loader } from '../Components/loader';
import { useGetFetch } from '../Hooks/useGetFetch';
import { ArtistsComponent } from '../Components/ArtistsComponent';

const Artist = () => {
  const [loading, setLoading] = useState(true);
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const searchedArtist = searchParams.get('search');

  const [searchInputArtistName, setSearchInputArtistName] =
    useState(searchedArtist);
  const [filteredEventList, setFilteredEventList] = useState();
  const [eventsListData, setEventsListData] = useState(null);

  const history = useHistory();

  const apiURL = `${baseURL}eventartist?related_events=true`;

  const [artistData, artistDataLoading] = useGetFetch(apiURL);

  useEffect(() => {
    setEventsListData(artistData.results);
  }, [artistData]);

  const handleFilterArtist = useCallback(() => {
    setLoading(true);
    history.push(`/artist/?search=${searchInputArtistName}`);
    const data = (eventsListData || []).filter((artist) => {
      if (!artist) return false;
      // Check if artist is included in the event
      return artist.first_name
        .toLowerCase()
        .includes(searchInputArtistName.toLowerCase());
    });
    // });
    setFilteredEventList(data);
    setLoading(false);
  }, [eventsListData, history, searchInputArtistName]);

  useEffect(() => {
    if (searchInputArtistName && eventsListData)
      handleFilterArtist(searchInputArtistName);
  }, [searchInputArtistName, eventsListData, handleFilterArtist]);

  return (
    <>
      <Helmet>
        <title>Artist | ARORA</title>
      </Helmet>

      <section className='bg-gray-200 pt-4 pb-12'>
        <div className=' main-container mt-14  pt-12'>
          <h1 className='heading'>ARORA</h1>
          <p className='text-center'>Search artwork based on artist name</p>

          <div className=' flex justify-center items-center mt-12'>
            <div className='relative  mt-1 shadow-sm '>
              <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center  md:pt-0 pl-1 md:pl-3'>
                <SearchIcon />
              </div>
              <input
                type='text'
                name='search'
                value={searchInputArtistName}
                onChange={(e) => setSearchInputArtistName(e.target.value)}
                className='border border-gray-400 bg-gray-100 placeholder-gray-700 w-[95vw] sm:w-full text-lightGreen md:pl-10 p-2  pl-5 focus:outline-none rounded-lg text-[12px] md:text-base'
                placeholder='Search by artist name'
              />
            </div>
          </div>
        </div>
      </section>

      {/* Events Card */}
      {artistDataLoading ? (
        <div className='flex h-screen w-screen justify-center items-center'>
          <Loader />
        </div>
      ) : (
        <section className='main-container'>
          {searchInputArtistName && eventsListData && filteredEventList
            ? filteredEventList.map((filteredEvent, i) => {
                return (
                  <div className='space-y-3' key={i}>
                    <ArtistsComponent
                      website={filteredEvent.website}
                      image={filteredEvent.profile_pic}
                      location={filteredEvent.short_address}
                      name={
                        filteredEvent.first_name + ' ' + filteredEvent.last_name
                      }
                      event={filteredEvent}
                      filteredEvents={true}
                      loading={loading}
                    />
                  </div>
                );
              })
            : eventsListData &&
              eventsListData.map((event, i) => {
                return (
                  <div className='space-y-3' key={i}>
                    <ArtistsComponent
                      website={event.website}
                      image={event.profile_pic}
                      location={event.short_address}
                      name={event.first_name + ' ' + event.last_name}
                      event={event}
                      filteredEvents={false}
                      loading={loading}
                    />
                  </div>
                );
              })}
        </section>
      )}
    </>
  );
};

export default Artist;
