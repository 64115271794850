/* eslint-disable react/prop-types */

export function ArtistCard(props) {
  return (
    <div className='flex flex-row items-center md:items-start  md:flex-col col-span-12 md:col-span-3  mt-2'>
      <img
        className='w-20 h-20 md:w-28 md:h-28 rounded-full object-cover object-top  border bg-gray-300'
        src={props.image}
        alt='pollinate'
      />
      <div className='ml-3 md:ml-0'>
        <p className='font-bold text-sm md:text-base mt-1 truncate '>
          {props.name}
        </p>
        {props?.website && (
          <a href={props.website} target='_blank' rel='noreferrer'>
            <div className='flex underline text-blue-700'>
              <p className='mr-1 text-sm md:text-base'>Visit Website</p>
            </div>
          </a>
        )}
      </div>
    </div>
  );
}
