/* eslint-disable react/prop-types */
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Munichlogo1 from '../Assets/Icons/logo1.png';
import pollonlogo1 from '../Assets/Icons/pollonlogo1.svg';
import xrHub from '../Assets/Icons/xrHub.png';
import monacensia from '../Assets/Icons/monacensia.png';
import XREnsemble from '../Assets/Icons/XrEnsemlie1.svg';
import ArroraGreenLogo from '../Assets/Icons/ARORAGREENLOGO.svg';
// import XREnsembleTransparent from '../Assets/Icons/XRELogo.png';
import GleichstellungstelleKlein from '../Assets/Icons/GleichstellungstelleKlein.png';
// import ZIZLogo from '../Assets/Icons/ZIZLogo.png';
import DenkFemale from '../Assets/Icons/DenkFemale.png';
import ZuknuftsfahigeWorms from '../Assets/Icons/ZuknuftsfahigeWorms.svg';

import React from 'react';

import { Loader } from './loader';

export const ImageCarousel = ({ selectedEvent, setOpenImageModal }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="sheet-ImageCarousel">
      <Slider
        className="w-11/12   mx-auto md:w-72 lg:w-96 mb-6 md:mb-0 md:mr-4"
        {...settings}
      >
        {selectedEvent?.images?.length > 0
          ? selectedEvent?.images?.map((item) => (
            <div
              onClick={() => {
                setOpenImageModal(true);
              }}
              className="cursor-pointer"
              key={item.id}
            >
              <img
                className=" h-64 sm:h-72 w-full border-2 border-gray-100 bg-gray-50 rounded-2xl object-contain"
                src={item.image_file}
                alt={item.id}
              />
            </div>
          ))
          : ''}
      </Slider>
    </div>
  );
};

// modalImageCarousel
export const ModalImageCarousel = ({ selectedEvent, setOpenImageModal }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="modal-carousel pb-5">
      <Slider className="w-full mb-0 " {...settings}>
        {selectedEvent?.images.map((item) => (
          <div
            onClick={() => {
              setOpenImageModal(true);
            }}
            className="cursor-pointer"
            key={item.id}
          >
            <img
              className=" h-[300px] md:h-[500px] lg:h-[550px]  mx-auto  min-w-full rounded-lg object-contain"
              src={item.image_file}
              alt={item.id}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export const BtnSlider = ({ cityName, handleSelectedCity, loading }) => {
  const cities = [
    { id: 2, name: 'NYC', logos: [XREnsemble, pollonlogo1] },
    {
      id: 6,
      name: 'Worms',
      logos: [
        ArroraGreenLogo,
        XREnsemble,
        GleichstellungstelleKlein,
        // ZIZLogo,
        DenkFemale,
        ZuknuftsfahigeWorms,
      ],
    },
    {
      id: 5,
      name: 'Munich (English)',
      logos: [XREnsemble, Munichlogo1, xrHub, monacensia, pollonlogo1],
    },
    {
      id: 4,
      name: 'München',
      logos: [XREnsemble, Munichlogo1, xrHub, monacensia, pollonlogo1],
    },
    { id: 3, name: 'Venice', logos: [XREnsemble, pollonlogo1] },
  ];

  const selectedCitiesTab = (condition) =>
    condition ? 'bg-lightGreen text-white' : 'bg-white text-lightGreen';

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    responsive: [
      {
        breakpoint: 4500,
        settings: { slidesToShow: 3, slidesToScroll: 3, initialSlide: 3 },
      },

      {
        breakpoint: 640,
        settings: { slidesToShow: 2, slidesToScroll: 2, initialSlide: 2 },
      },
    ],
  };

  return (
    <div className="sm:mt-1git0 md:mt-20 md:pb-20">
      <Slider className="w-[90%] max-w-7xl px-8  sm:pt-0 mx-auto" {...settings}>
        {cities.map(({ id, name, logos }) => (
          <div className="" key={id}>
            <button
              onClick={() => handleSelectedCity(id, name, logos)}
              disabled={loading}
              className={`${selectedCitiesTab(
                name === cityName
              )} rounded-xl md:rounded-2xl py-2 lg:py-4 font-Sunflower  px-2 w-[120px] sm:w-[155px] md:w-[180px] lg:w-[250px] text-lg sm:text-base md:text-lg lg:text-xl xl:text-3xl relative ${loading &&
                name === cityName &&
                'cursor-not-allowed text-lightGreen'
                }`}
            >
              {loading && name === cityName && (
                <Loader myClass="absolute lg:top-5 inset-x-5  " />
              )}

              {name}
            </button>
          </div>
        ))}
      </Slider>
    </div>
  );
};
