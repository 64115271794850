import React from 'react';
import { FacebookIcon, MailIcon, InstagramIcon, TwitterIcon } from './Icons';

const Footer = () => {
  return (
    <footer className="bg-gray-200 py-5 flex flex-col-reverse md:flex-row md:flex justify-between items-center px-14">
      <a
        href="https://www.pollinate.co/"
        target="_blank"
        rel="noreferrer"
        className="mt-3 md:mt-0 text-lightGreen"
      >
        Powered By: <span className="underline">Pollinate.Co</span>
      </a>
      <div className="flex space-x-3 ">
        <a
          href="https://www.instagram.com/xrensemble/"
          target="_blank"
          rel="noreferrer"
          className="bg-gray-100 hover:bg-white hover:border-lightGreen hover:border w-10 h-10 rounded-full flex justify-center items-center"
        >
          <InstagramIcon />
        </a>

        <a
          href="https://www.facebook.com/XRENSEMBLE"
          target="_blank"
          rel="noreferrer"
          className="bg-gray-100 hover:bg-white hover:border-lightGreen hover:border w-10 h-10 rounded-full flex justify-center items-center"
        >
          <FacebookIcon />
        </a>
        <a
          href="https://twitter.com/xrensemble/"
          target="_blank"
          rel="noreferrer"
          className="bg-gray-100 hover:bg-white hover:border-lightGreen hover:border w-10 h-10 rounded-full flex justify-center items-center"
        >
          <TwitterIcon />
        </a>
        <a
          href="mailto:xrensemble@gmail.com"
          target="_blank"
          rel="noreferrer"
          className="bg-gray-100 hover:bg-white hover:border-lightGreen hover:border  w-10 h-10 rounded-full flex justify-center items-center"
        >
          <MailIcon />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
